/* eslint-disable cloud-website/no-anchor */
import type { ComponentPropsWithoutRef } from "react"
import { forwardRef } from "react"

type ExternalLinkProps = ComponentPropsWithoutRef<"a"> & {
  disabled?: boolean
}

/** An external anchor with rel="noopener noreferrer" (referral is allowed when referrerPolicy is provided) already in. Also slottable. */
export const ExternalLink = forwardRef<HTMLAnchorElement, ExternalLinkProps>(({ target, children, ...rest }, ref) => {
  if (rest.disabled) {
    return (
      // @ts-expect-error - We're disabling the anchor, so we need to prevent the default behavior in case this is composed with a button.
      <span {...rest} ref={ref} onClick={e => e.preventDefault()} disabled data-disabled>
        {children}
      </span>
    )
  }

  // Check if the current origin matches the target origin. If so, set the target to "_self".
  return (
    <a
      target={target || (rest.href?.startsWith(window.location.origin) ? "_self" : "_blank")}
      rel={`noopener${rest.referrerPolicy ? "" : " noreferrer"}`}
      {...rest}
      ref={ref}>
      {children}
    </a>
  )
})

ExternalLink.displayName = "ExternalLink"
