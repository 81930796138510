import { createFileRoute, redirect } from "@tanstack/react-router"

import { query } from "@/api/query"
import { MY_SPACE } from "@/constants/spaces"
import { routeGuard } from "@/lib/routing/beforeLoad"

type Tab = "custom" | "arduino"

type ProductSearch = {
  tab?: Tab
}

export const Route = createFileRoute("/_authlayout/_sidebar/templates/")({
  staticData: {
    title: "Templates"
  },
  validateSearch: (search: Record<string, unknown>): ProductSearch => ({
    tab: search.tab === "arduino" || search.tab === "custom" ? search.tab : undefined
  }),
  beforeLoad: async ({ context, search, location }) => {
    const { queryClient, spaceStorage } = context
    const spaces = await queryClient.ensureQueryData(query.space.list)
    const { space } = spaces.find(sp => sp.id === spaceStorage.getSpace()) || MY_SPACE

    if (!search.tab) {
      redirect({
        to: location.pathname,
        throw: true,
        search: {
          tab: space === "pro" ? "custom" : "arduino"
        }
      })
    }
    await routeGuard(context)
  },
  loader: ({ context: { queryClient } }) => {
    queryClient.ensureQueryData(query["iot-templates"].list)
  }
})
